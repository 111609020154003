<template>
    <div id="governorates">
      <div class="container-fluid">
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col cols="3">
                    <b-form-input
                      type="text"
                      :placeholder="$t('filter-number')"
                      v-model="filterData.reservation_no"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col cols="3">
                    <v-select v-model="filterData.hotel_id" :options="hotels.data" :reduce="(option)=>option.id"
                                label="title" @search="handleHotelSearch" />
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('reservation-list') }}</h4>
            </template>
            <template v-slot:body>
              <b-table responsive :items="reservations.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(client)="data">
                    <h6>{{ data.item.client.name }}</h6>
                    <ul class="list-unstyled d-flex align-items-center" style="color: #888;gap: 10px;">
                        <li>
                            <i class="fas fa-child"></i>
                            <span>
                                {{ data.item.children }} {{ $t('child') }}
                            </span>
                        </li>
                        <li>
                            <i class="fas fa-user-friends"></i>
                            <span>
                                {{ data.item.adults }} {{ $t('adult') }}
                            </span>
                        </li>
                    </ul>
                  </template>
                <template v-slot:cell(actions)="{item}">
                    <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                      <b-button v-b-tooltip.top="$t('download')" variant=" iq-bg-success" @click="$store.dispatch('downloadReservation', item)"  size="md">
                        <i class="ri-file-download-line"></i>
                      </b-button>
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  @change="filtering()"
                  v-model="filterData.page"
                  :total-rows="reservations.total"
                  :per-page="reservations.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
import debounce from 'debounce'

export default {
  name: 'governorates',
  data () {
    return {
      filterData: {
        page: 1,
        take: 10,
        reservation_no: '',
        hotel_id: ''
      }
    }
  },
  computed: {
    reservations () {
      return this.$store.getters.reservstionsList
    },
    fields () {
      return [
        {
          label: this.$t('reservation-No'),
          key: 'reservation_no'
        },
        {
          label: this.$t('client-name'),
          key: 'client'
        },
        {
          label: this.$t('phone'),
          key: 'phone'
        },
        {
          label: this.$t('hotel'),
          key: 'hotel.title'
        },
        {
          label: this.$t('checkin'),
          key: 'checkin'
        },
        {
          label: this.$t('checkout'),
          key: 'checkout'
        },
        {
          label: this.$t('arrival-time'),
          key: 'arrival_time'
        },
        {
          label: this.$t('price'),
          key: 'total_price'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.$store.dispatch('getReservationList', this.filterData)
    },
    handleHotelSearch: debounce(function (title) {
      if (!title || title.length < 1) return
      this.getHotels({
        page: 1,
        title
      })
    }, 1000)
  },
  watch: {
    filterData: {
      handler: function (value) {
        this.$store.dispatch('getReservationList', this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getHotels()
    this.$store.dispatch('getReservationList')
  }
}
</script>
